(function () {
  'use strict';

  class NavBarCtrl {
    constructor($rootScope, $mdSidenav, Signup, Login, $mdMedia, Utils) {
      let vm = this;
      vm.ctrlName = 'NavBarCtrl';
      vm.$rootScope = $rootScope;
      vm.Utils = Utils;
      vm.$mdSidenav = $mdSidenav;
      vm.Signup = Signup;
      vm.Login = Login;
      vm.$mdMedia = $mdMedia;
    }
    toggleSide() {
      let vm = this;
      vm.$mdSidenav('left')
        .toggle();
    }
    showProntLogin(ev) {
      const vm = this;
      vm.Login.show(ev);
    }
    showProntSignup(ev) {
      const vm = this;
      vm.Signup.show(ev);
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:NavBarCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('NavBarCtrl', NavBarCtrl);
}());
